import { Pto } from '@outcoreaix/pto'
import { RcFile } from 'antd/es/upload'
import { authStorage } from 'app/auth-storage'
import { getQueryParamString } from 'utils/get-query-param-string'
import { apiSlice } from './api-splice'

export const spacesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpace: builder.query<Pto.Spaces.Space, number>({
      query: (id) => ({
        url: `spaces/${id}`,
        method: 'GET'
      }),
      providesTags: ['SingleSpace']
    }),
    getSpaces: builder.query<Pto.Spaces.List, Pto.Spaces.SpaceListQuery>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `spaces?${queryParams}`,
          method: 'GET'
        }
      },
      providesTags: ['Spaces']
    }),
    addSpace: builder.mutation<Pto.Spaces.Space, Pto.Spaces.CreateSpace>({
      query: (data) => ({
        url: 'spaces',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Spaces']
    }),
    addSpaceMembers: builder.mutation<void, Pto.Spaces.AddSpaceMembers & { spaceId: number }>({
      query: ({ spaceId, ...rest }) => ({
        url: `spaces/${spaceId}/members`,
        method: 'POST',
        body: rest
      }),
      invalidatesTags: ['SpaceMembers']
    }),
    activateMember: builder.mutation<void, { memberId: number; spaceId: number }>({
      query: ({ spaceId, memberId }) => ({
        url: `spaces/${spaceId}/activate-member/${memberId}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['SpaceMembers']
    }),
    deactivateMember: builder.mutation<void, { memberId: number; spaceId: number }>({
      query: ({ spaceId, memberId }) => ({
        url: `spaces/${spaceId}/deactivate-member/${memberId}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['SpaceMembers']
    }),
    updateSpace: builder.mutation<void, Pto.Spaces.UpdateSpace & { id: number }>({
      query: ({ id, ...rest }) => ({
        url: `spaces/${id}`,
        method: 'PATCH',
        body: { ...rest }
      }),
      invalidatesTags: ['Spaces', 'SingleSpace']
    }),
    updateSpaceMemberByUser: builder.mutation<void, Partial<Pto.Spaces.UpdateSpaceMember> & { spaceId: number }>({
      query: ({ spaceId, ...rest }) => ({
        url: `spaces/${spaceId}/update-space-member-by-user`,
        method: 'PATCH',
        body: { ...rest, userId: authStorage.user!.id }
      }),
      invalidatesTags: ['SpaceMembers']
    }),
    updateSpaceMembers: builder.mutation<void, { file: RcFile; id: number }>({
      query: ({ id, file }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)
        return {
          url: `spaces/update-space-contacts/${id}`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['Spaces', 'SpaceMembers', 'Members', 'SingleSpace']
    }),
    getSpaceMembers: builder.query<Pto.Spaces.MemberList, Pto.Spaces.MemberListQuery>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `spaces/members/list/?${queryParams}`,
          method: 'GET'
        }
      },
      providesTags: ['SpaceMembers']
    }),
    getSpaceMembersCSVLink: builder.query<Pto.Spaces.SpaceMembersCsv, Pto.Spaces.MemberListQuerySV>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `spaces/members/csv/?${queryParams}`,
          method: 'GET'
        }
      },
      providesTags: ['SpaceMembers']
    }),
    getSpaceMember: builder.query<Pto.Spaces.SpaceMember, { spaceId: number; id: number; type: 'users' | 'members' }>({
      query: ({ spaceId, id, type }) => ({
        url: `spaces/${spaceId}/${type}/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, { id, type }) =>
        type === 'users' ? [{ type: 'SpaceMembers', userId: id }] : [{ type: 'SpaceMembers', id }]
    }),
    deleteSpaceMember: builder.mutation<void, { spaceId: number; memberId: number }>({
      query: ({ spaceId, memberId }) => ({
        url: `spaces/${spaceId}/members/${memberId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['SpaceMembers']
    }),
    getMembersDashboard: builder.query<Pto.Spaces.Dashboard, Pto.Spaces.DashboardQuery>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `spaces/members/dashboard/?${queryParams}`,
          method: 'GET'
        }
      }
    }),
    getMemberAnswers: builder.query<Pto.Spaces.MemberAnswer[], number>({
      query: (spaceId) => ({
        url: `spaces/${spaceId}/member-answers`,
        method: 'GET'
      }),
      providesTags: ['MemberAnswers']
    }),
    getMemberAnswersByMemberId: builder.query<Pto.Spaces.MemberAnswer[], { spaceId: number; memberId: number }>({
      query: ({ spaceId, memberId }) => ({
        url: `spaces/${spaceId}/member/${memberId}/member-answers`,
        method: 'GET'
      }),
      providesTags: ['MemberAnswers']
    }),
    updateSpaceKnowledgeBase: builder.mutation<void, { file: RcFile; id: number }>({
      query: ({ id, file }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)

        return {
          url: `spaces/${id}/knowledge-base`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Spaces', 'SingleSpace']
    }),
    updateSpaceAIConfig: builder.mutation<void, Pto.Spaces.UpdateSpaceAIConfig & { id: number }>({
      query: ({ id, ...data }) => {
        return {
          url: `spaces/${id}/ai-config`,
          method: 'PATCH',
          body: data,
          prepareHeaders: (headers: any) => {
            headers.set('Content-Type', 'text/plain')
            return headers
          }
        }
      },
      invalidatesTags: ['Spaces', 'SingleSpace']
    }),
    updateSpaceAIAvatar: builder.mutation<void, { id: number; file: RcFile }>({
      query: ({ id, file }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)

        return {
          url: `spaces/${id}/ai-avatar`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Spaces', 'SingleSpace']
    }),
    addSpaceQuestions: builder.mutation<void, { createQuestionsDto: Pto.Spaces.CreateSpaceQuestion[]; id: number }>({
      query: ({ id, createQuestionsDto }) => ({
        url: `spaces/${id}/questions`,
        method: 'POST',
        body: createQuestionsDto
      }),
      invalidatesTags: ['Spaces', 'SingleSpace']
    }),
    addQuestionAnswer: builder.mutation<any, any>({
      query: ({ id, ...rest }) => ({
        url: `spaces/${id}/answer`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: ['MemberAnswers']
    }),
    deleteSpace: builder.mutation<void, number>({
      query: (id) => ({
        url: `spaces/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Spaces', 'SingleSpace']
    }),
    generateSummary: builder.mutation<Pto.Spaces.SpaceMember, number>({
      query: (id) => ({
        url: `spaces/${id}/generate-summary`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Spaces', 'MemberStatus', 'SingleSpace']
    }),
    updateSummary: builder.mutation<void, Pto.Spaces.UpdateSpaceMemberSummary & { id: number }>({
      query: ({ id, ...rest }) => ({
        url: `spaces/${id}/update-summary`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: ['Spaces', 'MemberStatus']
    }),
    checkMembership: builder.query<
      Pto.Spaces.SpaceWithAnswers | undefined,
      Partial<Pto.Spaces.CheckUncompletedMembershipQuery>
    >({
      query: (params) => {
        const queryString = getQueryParamString(params)

        return {
          url: `/spaces/check/uncompleted-membership?${queryString}`,
          method: 'GET'
        }
      },
      providesTags: ['MemberStatus']
    }),
    createSpaceToken: builder.mutation<
      Pto.Spaces.SpaceInvitationToken,
      Pto.Spaces.CreateSpaceInvitationToken & { spaceId: number }
    >({
      query: ({ spaceId, ...body }) => ({
        url: `spaces/${spaceId}/invitation-token`,
        method: 'POST',
        body
      })
    }),
    convertAudioToText: builder.mutation<{ text: string }, { file: Blob; id: number }>({
      query: ({ id, file }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)

        return {
          url: `spaces/${id}/audio-to-text`,
          method: 'POST',
          body
        }
      }
    }),
    getSpaceToken: builder.query<Pto.Spaces.SpaceInvitationToken | undefined, number>({
      query: (spaceId) => ({
        url: `spaces/${spaceId}/space-token`,
        method: 'GET'
      })
    }),
    getSpacePluginToken: builder.query<Pto.Spaces.SpaceInvitationToken | undefined, number>({
      query: (spaceId) => ({
        url: `spaces/${spaceId}/space-plugin-token`,
        method: 'GET'
      }),
      providesTags: ['SpacePluginToken']
    }),
    createSpacePluginToken: builder.mutation<
      Pto.Spaces.SpaceInvitationToken,
      Pto.Spaces.CreateSpacePluginInvitationToken & { spaceId: number }
    >({
      query: ({ spaceId, ...body }) => ({
        url: `spaces/${spaceId}/plugin-invitation-token`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['SpacePluginToken']
    }),
    createSpaceAIPlugin: builder.mutation<
      Pto.Spaces.SpaceAIPlugin,
      Pto.Spaces.CreateSpaceAIPlugin & { spaceId: number }
    >({
      query: ({ spaceId, ...body }) => ({
        url: `spaces/${spaceId}/ai-plugin`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['SpaceAIPlugin']
    }),
    getSpaceAIPlugin: builder.query<Pto.Spaces.SpaceAIPlugin | undefined, number>({
      query: (spaceId) => ({
        url: `spaces/${spaceId}/ai-plugin`,
        method: 'GET'
      }),
      providesTags: ['SpaceAIPlugin']
    })
  })
})

export const {
  useGetSpaceQuery,
  useAddSpaceMutation,
  useActivateMemberMutation,
  useDeactivateMemberMutation,
  useAddSpaceMembersMutation,
  useUpdateSpaceKnowledgeBaseMutation,
  useUpdateSpaceAIConfigMutation,
  useAddSpaceQuestionsMutation,
  useUpdateSpaceMutation,
  useUpdateSpaceMembersMutation,
  useGetSpacesQuery,
  useGetSpaceMembersQuery,
  useGetSpaceMembersCSVLinkQuery,
  useGetSpaceMemberQuery,
  useGetMembersDashboardQuery,
  useDeleteSpaceMutation,
  useDeleteSpaceMemberMutation,
  useAddQuestionAnswerMutation,
  useGenerateSummaryMutation,
  useUpdateSummaryMutation,
  useCheckMembershipQuery,
  useUpdateSpaceMemberByUserMutation,
  useGetSpaceTokenQuery,
  useLazyGetSpaceMembersQuery,
  useCreateSpaceTokenMutation,
  useUpdateSpaceAIAvatarMutation,
  useGetMemberAnswersQuery,
  useCreateSpaceAIPluginMutation,
  useCreateSpacePluginTokenMutation,
  useGetSpacePluginTokenQuery,
  useGetSpaceAIPluginQuery,
  useGetMemberAnswersByMemberIdQuery,
  useConvertAudioToTextMutation
} = spacesApi
