import { Button, Table, TableColumnsType, TablePaginationConfig, TableProps } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

const paginationConfig: TablePaginationConfig = {
  showSizeChanger: false,
  hideOnSinglePage: true,
  position: ['bottomCenter'],
  responsive: true,
  className:
    'items-center ant-pagination-prev-button-stick-left ant-pagination-next-button-stick-right sm:h-[36px] md:h-[42px]',
  itemRender: (currentPage, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Button
          size="large"
          type="default"
          className="flex items-center gap-2 border border-[#D0D5DD] rounded-lg px-4 py-2 text-sm h-full"
          icon={<ArrowLeftOutlined className="text-xl" />}
          disabled={currentPage === 1}
        >
          <span className="!hidden md:!inline">Previous</span>
        </Button>
      )
    }
    if (type === 'next') {
      return (
        <Button
          size="large"
          type="default"
          className="flex items-center gap-2 border border-[#D0D5DD] rounded-lg px-4 py-2 text-sm h-full"
          icon={<ArrowRightOutlined className="text-xl" />}
          disabled={currentPage === paginationConfig.total}
        >
          <span className="!hidden md:!inline">Next</span>
        </Button>
      )
    }
    return originalElement
  }
}

type Props<T> = {
  rowKey: string
  isLoading: boolean
  page: number
  columns: TableColumnsType<T>
  data?: { total: number; items: T[] }
  pageSize: number
  onPageChange: (page: number) => void
} & TableProps<T>

const TableWithCustomPagination = <T,>({
  rowKey,
  isLoading,
  page,
  columns,
  data,
  pageSize,
  onPageChange,
  ...props
}: Props<T>) => {
  return (
    <Table<T>
      key={page}
      rowKey={rowKey}
      bordered
      loading={isLoading}
      dataSource={data?.items || []}
      pagination={{
        current: page,
        total: data?.total || 0,
        pageSize,
        onChange: onPageChange,
        ...paginationConfig
      }}
      scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
      columns={columns}
      {...props}
    />
  )
}

export default TableWithCustomPagination
