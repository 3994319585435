import { FileTextOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import { FC } from 'react'

type Props = {
  className?: string
  buttonText?: string
  title?: string
  subtitle?: string
  downloadButtonType: 'default' | 'link' | 'primary'
  downloadTemplateFunction: () => void
  downloadContactsFunction?: () => void
}

const DownloadTemplateButton: FC<Props> = ({
  className,
  buttonText = 'Download Template',
  title = 'Need a template?',
  subtitle = 'Use our pre-formatted CSV template for quick imports',
  downloadButtonType,
  downloadTemplateFunction,
  downloadContactsFunction
}) => {
  return (
    <div className={`bg-blue-50 rounded-lg shadow p-4 ${className}`}>
      <div className="flex items-center">
        <div className="flex items-center">
          <FileTextOutlined className="w-6 h-6 text-blue-500 mr-3 text-xl font-bold" />
          <div className="min-w-36">
            <p className="font-semibold text-gray-900">{title}</p>
            <p className="text-sm text-gray-500 mt-1">{subtitle}</p>
          </div>
        </div>
        <Button
          className={`ml-auto py-2 px-4 rounded-md  min-h-10 whitespace-normal ${downloadButtonType === 'link' ? 'border-none' : 'border-background'}`}
          onClick={downloadTemplateFunction}
          type={downloadButtonType}
        >
          {buttonText}
        </Button>
      </div>
      {downloadContactsFunction && (
        <>
          <Divider className="my-3" />
          <p className="text-sm">
            Please{' '}
            <span className="text-blue-600 font-medium cursor-pointer" onClick={downloadContactsFunction}>
              click here
            </span>{' '}
            to download your existing contact list to make updates to them
          </p>
        </>
      )}
    </div>
  )
}

export default DownloadTemplateButton
