import { useState } from 'react'
import { Modal, Form, Input, Rate, Button } from 'antd'
import { toast } from 'react-toastify'
import { useSubmitFeedbackMutation } from 'api/users-api'
import { useWorkspace } from 'hooks/useWorkspace'
import { useParams } from 'react-router-dom'
import { Pto } from '@outcoreaix/pto'

type FormProps = {
  starsAmount: number
  description: string
}

const Feedback = () => {
  const params = useParams()
  const { currentWorkspace, currentWorkspaceId } = useWorkspace()

  const [submitFeedback] = useSubmitFeedbackMutation()

  const [form] = Form.useForm<FormProps>()

  const [isOpen, setIsOpen] = useState(false)
  const [fieldsWereTouched, setFieldsWereTouched] = useState(false)
  const [isStarsSelected, setIsStarsSelected] = useState(false)

  const onStarsChange = () => {
    setIsStarsSelected(true)
  }

  const handleOpenModal = async () => {
    setIsOpen(true)
  }

  const onSubmit = async (values: FormProps) => {
    await submitFeedback({
      starsAmount: values.starsAmount,
      description: values.description || '',
      workspaceId: currentWorkspaceId as number,
      spaceId:
        params.spaceId && currentWorkspace?.currentMember?.role === Pto.Workspaces.WorkspaceRole.Contact
          ? Number(params.spaceId)
          : null
    })
      .unwrap()
      .then(async () => {
        await onCancel()
      })
      .catch(() => {
        toast.error('Failed to send feedback')
      })
  }

  const onCancel = async () => {
    setIsOpen(false)
    form.resetFields()
    setIsStarsSelected(false)
    setFieldsWereTouched(false)
  }

  return (
    <>
      <Button className="absolute top-2 right-2 md:right-2 z-20 md:mr-2 md:mt-2 text-sm" onClick={handleOpenModal}>
        Feedback
      </Button>
      <Modal
        open={isOpen}
        onCancel={onCancel}
        cancelText="Skip"
        okButtonProps={{ disabled: !isStarsSelected || !fieldsWereTouched }}
        okText={isStarsSelected ? 'Submit' : 'Save'}
        destroyOnClose
        onOk={() => form.submit()}
        className="feedback-modal max-w-96 text-base"
      >
        <h2 className="text-center m-3 mb-5">We would love your Feedback!</h2>
        <p className="text-center">
          We hope that you are enjoying using OutCore AI. How would you rate your experience today?
        </p>
        <Form
          layout="vertical"
          onFinish={onSubmit}
          form={form}
          onValuesChange={() => setFieldsWereTouched(true)}
          className="mb-7"
        >
          <Form.Item
            className="rate-container"
            name="starsAmount"
            rules={[{ required: true, message: 'Please rate the app' }]}
          >
            <Rate onChange={onStarsChange} allowClear />
          </Form.Item>
          {isStarsSelected && (
            <Form.Item label="Leave a comment to share more feedback" name="description">
              <Input.TextArea autoSize />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default Feedback
