import { AppstoreOutlined, CustomerServiceOutlined, PlusOutlined, StopOutlined, UserOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { AppRoutes } from 'app/app-routes'
import { FC, useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { generateSpaceSettingsMenu, generateWorkspaceSettingsMenu } from '../menu-items'
import { useGetSpacesQuery } from 'api/spaces-api'
import { useGetWorkspacesOptionsQuery } from 'api/workspaces-api'
import { Pto } from '@outcoreaix/pto'
import Icon from '@ant-design/icons/lib/components/Icon'

const SettingsMenu: FC = () => {
  const param = useParams()
  const { data: workspaces } = useGetWorkspacesOptionsQuery()
  const currentWorkspace = param.workspaceId
    ? workspaces?.find((item) => item.id === Number(param.workspaceId))
    : undefined

  const { data } = useGetSpacesQuery(
    {
      workspaceId: Number(param.workspaceId)
    },
    { skip: param.workspaceId === undefined }
  )
  const navigate = useNavigate()
  // TODO: add loading state
  const spaces = useMemo(
    () => data?.items.filter((item) => item.workspaceId === Number(currentWorkspace?.id)),
    [currentWorkspace, data]
  )

  const isContact =
    workspaces?.find((item) => item.id === Number(param.workspaceId))?.currentMember?.role ===
    Pto.Workspaces.WorkspaceRole.Contact

  return (
    <div className="flex flex-col h-screen overflow-hidden w-full">
      <Menu
        theme="light"
        mode="inline"
        id="settings"
        className="h-full py-6 px-4 overflow-y-auto border-r border-menu"
        triggerSubMenuAction="click"
        defaultOpenKeys={[`space_${param.spaceId}`, `workspace_${param.workspaceId}`]}
        defaultSelectedKeys={[location.pathname]}
        items={[
          {
            key: `${AppRoutes.settings}/${currentWorkspace?.id}/profile-settings`,
            icon: <UserOutlined />,
            label: <Link to={`${AppRoutes.settings}/${currentWorkspace?.id}/profile-settings`}>Profile settings</Link>,
            style: { paddingLeft: '12px' }
          },
          {
            key: `${AppRoutes.settings}/${currentWorkspace?.id}/support`,
            icon: <CustomerServiceOutlined />,
            label: <Link to={`${AppRoutes.settings}/${currentWorkspace?.id}/support`}>Support</Link>,
            style: { paddingLeft: '12px' }
          },
          ...(!isContact
            ? [
                {
                  key: 'workspace',
                  type: 'group' as const,
                  label: (
                    <div className="flex gap-2 items-center">
                      <AppstoreOutlined className="w-4 h-4" />
                      Workspace
                    </div>
                  ),
                  children: [
                    {
                      key: `workspace_${param.workspaceId}`,
                      label: (
                        <div className="pl-1 overflow-hidden text-ellipsis whitespace-nowrap max-w-[165px]">
                          {currentWorkspace?.name}
                        </div>
                      ),
                      children: generateWorkspaceSettingsMenu(currentWorkspace)
                    }
                  ]
                },
                {
                  key: 'spaces',
                  type: 'group' as const,
                  label: (
                    <div className="flex gap-2 items-center">
                      <Icon
                        className="w-4 h-4"
                        component={() => (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="currentColor">
                            <path d="m11 1 .447-.894a1 1 0 0 0-.894 0L11 1ZM1 6l-.447-.894a1 1 0 0 0 0 1.788L1 6Zm10 5-.447.894a1 1 0 0 0 .894 0L11 11Zm10-5 .447.894a1 1 0 0 0 0-1.788L21 6ZM1.447 15.106a1 1 0 1 0-.894 1.788l.894-1.788ZM11 21l-.447.894a1 1 0 0 0 .894 0L11 21Zm10.447-4.106a1 1 0 1 0-.894-1.788l.894 1.788Zm-20-6.788a1 1 0 1 0-.894 1.788l.894-1.788ZM11 16l-.447.894a1 1 0 0 0 .894 0L11 16Zm10.447-4.106a1 1 0 1 0-.894-1.788l.894 1.788ZM10.553.106l-10 5 .894 1.788 10-5-.894-1.788Zm-10 6.788 10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Zm10-6.788-10-5-.894 1.788 10 5 .894-1.788ZM.553 16.894l10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Zm-10.894-10 10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Z" />
                          </svg>
                        )}
                      />
                      Spaces
                    </div>
                  ),
                  children: [
                    ...(spaces || [])?.map((space) => ({
                      key: `space_${space.id}`,
                      label: (
                        <div className="pl-1 overflow-hidden text-ellipsis whitespace-nowrap max-w-[165px]">
                          {space.status === Pto.Spaces.SpaceStatus.Inactive && (
                            <StopOutlined className="text-red-500 mr-1" />
                          )}
                          {space.name}
                        </div>
                      ),
                      children: generateSpaceSettingsMenu(
                        currentWorkspace?.id,
                        space.id,
                        space.currentSpaceMember?.role
                      )
                    })),
                    ...(currentWorkspace?.currentMember?.role === Pto.Workspaces.WorkspaceRole.Admin
                      ? [
                          {
                            key: `space_create_${currentWorkspace?.id}`,
                            label: (
                              <button
                                onClick={() => navigate(`${AppRoutes.workspaces}/${currentWorkspace?.id}/space/set-up`)}
                                className="pl-1 justify-center items-center [&>span]:!m-0 flex gap-2 font-medium"
                              >
                                <PlusOutlined />
                                <div>Create space</div>
                              </button>
                            )
                          }
                        ]
                      : [])
                  ]
                }
              ]
            : [])
        ]}
      />
    </div>
  )
}

export default SettingsMenu
