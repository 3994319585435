import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import { toast } from 'react-toastify'
import { Pto } from '@outcoreaix/pto'
import axios from './axios'
import { apiSlice } from 'api/api-splice'
import { store } from './store'

class AuthStorage extends EventTarget {
  public accessToken: string | null = null
  public user: Pto.Auth.JwtUser | null = null
  public pendingPhoneVerification: boolean = false
  public phoneNumberForVerification: string | undefined = undefined

  constructor() {
    super()
    this.accessToken = localStorage.getItem('accessToken')
    this.pendingPhoneVerification = !!this.getTemporaryToken()
    this.phoneNumberForVerification = this.getTemporaryPhone()
    const user = localStorage.getItem('user')

    if (user) {
      this.user = JSON.parse(user)
    }
  }

  async login(accessToken: string) {
    try {
      this.pendingPhoneVerification = false
      this.setPendingUserNameSet(false)
      const nonce = this.getInviteToken()
      const { data: user } = await axios.post<Pto.Auth.JwtUser>(`/users/auth?idToken=${accessToken}&nonce=${nonce}`)
      if (!user) {
        toast.error('User not found')
        this.signOut()
        return
      }

      if (!isNaN(parseInt(user.lastName))) {
        this.setPendingUserNameSet(true)
      }

      // if (!user.isPhoneVerified && !user.roles.includes(Pto.Users.UserRole.SystemAdmin)) {
      //   this.setTemporaryToken(accessToken)
      //   this.pendingPhoneVerification = true
      //   this.setTemporaryPhone(user.phone)
      //   this.phoneNumberForVerification = user.phone || undefined
      //   return
      // }

      this.setTemporaryToken()
      this.setInviteToken()
      this.setSpaceToken()
      this.setAccessToken(accessToken)
      this.setUser(user)
    } catch (error) {
      toast.error('Failed to login')
      this.signOut()
    }
  }

  setPendingUserNameSet(value: boolean) {
    localStorage.setItem('pendingUserNameSet', value ? 'true' : 'false')
  }

  getPendingUserNameSet() {
    return localStorage.getItem('pendingUserNameSet') === 'true'
  }
  setInviteToken(inviteToken?: string) {
    if (!inviteToken) {
      localStorage.removeItem('inviteToken')
      return
    }

    localStorage.setItem('inviteToken', inviteToken)
  }

  getInviteToken(): string | undefined {
    return localStorage.getItem('inviteToken') || undefined
  }

  setSpaceToken(inviteToken?: string) {
    if (!inviteToken) {
      localStorage.removeItem('spaceToken')
      return
    }

    localStorage.setItem('spaceToken', inviteToken)
  }

  getSpaceToken(): string | undefined {
    return localStorage.getItem('spaceToken') || undefined
  }

  async fetchSession(forceRefresh: boolean = false) {
    if (!this.accessToken) {
      return
    }

    const { tokens } = await fetchAuthSession({ forceRefresh })

    if (!tokens?.idToken) {
      toast.error('Token not found')
      return this.signOut()
    }

    this.setAccessToken(tokens.idToken.toString())

    const { data: user } = await axios.get<Pto.Auth.JwtUser>('/users/me')

    if (!user) {
      toast.error('User not found')
      return this.signOut()
    }

    if (!isNaN(parseInt(user.lastName))) {
      this.setPendingUserNameSet(true)
    }

    this.setUser(user)
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken
    localStorage.setItem('accessToken', accessToken.toString())
  }

  setUser(user: Pto.Auth.JwtUser) {
    // eslint-disable-next-line
    // @ts-ignore
    window.heap?.identify(user.id)
    // eslint-disable-next-line
    // @ts-ignore
    window.heap?.addUserProperties({ Name: `${user.firstName} ${user.lastName}`, email: user.email })
    this.user = user
    localStorage.setItem('user', JSON.stringify(user))
    this.dispatchEvent(new CustomEvent('user-signed-in', { detail: { user } }))
  }

  setTemporaryToken(accessToken?: string) {
    if (!accessToken) {
      localStorage.removeItem('temporaryToken')
      return
    }

    localStorage.setItem('temporaryToken', accessToken)
  }

  getTemporaryToken(): string | null {
    return localStorage.getItem('temporaryToken')
  }

  setTemporaryPhone(phone?: string) {
    if (!phone) {
      localStorage.removeItem('temporaryPhone')
      return
    }

    localStorage.setItem('temporaryPhone', phone)
  }

  getTemporaryPhone(): string | undefined {
    return localStorage.getItem('temporaryPhone') || undefined
  }

  userNameSetNeeded(): boolean {
    return !isNaN(parseInt(this.user?.lastName || ''))
  }

  signOut() {
    store.dispatch(apiSlice.util.resetApiState())
    const wasUser = !!this.user

    const signOutFromApp = async () => {
      await signOut()

      if (wasUser) {
        this.dispatchEvent(new CustomEvent('user-signed-out'))
      }

      this.accessToken = null
      this.user = null
      this.pendingPhoneVerification = false
      this.setTemporaryToken()
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      localStorage.removeItem('inviteToken')
      localStorage.removeItem('spaceToken')
    }

    void signOutFromApp()
  }
}

export const authStorage = new AuthStorage()
